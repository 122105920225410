<template>
<!--Header-->
<div class= "button">
  <div class="mb-20">

     <div class= "flex flex-initial">
        <div class="place-items-start fixed top-0 md:top-48 right-0 w-1/2 md:w-1/3 z-02">
          <img src = "/images/newgraph.png">
        </div>
      </div>
    </div>
    </div>

<!--Content-->
<div class= "grid grid-cols-6 gap-0">

<div class ="box-border mb-3 border-2 p-10 rounded-md col-start-1 col-span-6 flex flex-col items-center justify-center text-center font-sans text-lg text-black scroll:text-pink bg-tq">
                <h class="text-xl text-bold text-indigo-900"> Interested in Persons Unknown?
                <br/> <br/></h>
                <p>   
               Come and meet us! We have an open meeting on the last Tuesday of the month. Email us for more details:
               personsunknowncoop@gmail.com
               <br/><br/>
               </p>
               

  </div>

  <div class= "box-border mb-3 border-0">
  </div>
  <div class ="box-border mb-3 border-2 p-10 rounded-md col-start-1 col-span-6 flex flex-col items-center justify-center text-center font-sans text-lg text-black scroll:text-pink bg-pink">
                <h class="text-xl text-bold text-indigo-900"> Joining Persons Unknown
                <br/><br/></h>

                 Co-operatives are self-managed organisations and rely on the involvement of their members so regular attendance of meetings and active involvement in the co-operative’s management is expected. New members should also acquaint themselves with the housing co-operative structure, decision making process, Radical Routes and our secondary and primary rules. Download them here:
               <br/> <br/>

               <div class="grid grid-cols-2 p-5">
    <button class="bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded inline-flex items-center">
     <a href="/images/Primary Rules.pdf">
      <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
     <span>Primary Rules</span></a>
     </button>
     

     <button class="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded inline-flex items-center">
     <a href="/images/[DO NOT EDIT] Secondary Rules Orignal.pdf">
     <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
    <span>Secondary Rules</span></a>
    </button>
    <br/> <br/>
    
               </div>      
                <p>    <!-- on scroll turn text tex-grey-700 -->
               As we are unhoused, becoming a member of Persons Unknown currently involved attending our monthly open meetings and getting involved in the running of the co-operative. This could include joining a working group such as the finance, outreach or communication groups. We aim to buddy new members with older members and have opertunities to shadow any role within the co-op. 
               
               Email us and let us know a bit about yourself and why you are interested in the co-op and we will send you our New Members Pack and up to date info on where we are up to in the process of building the co-operative. 
               <br/><br/>
                </p>
  

               <p>
                 <br/>
                If you would like to apply to join Persons Unknown, email us a completed joining <span class="text-indigo-900"> application form:</span><br/><br/>
<button class="bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded inline-flex items-center">
  <a href="/images/New Member Form.pdf">
  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
  <span>Application Form</span></a>
</button>      </p>

   </div>
   <div class ="box-border mb-3 border-2 p-10 rounded-md col-start-1 col-span-6 flex flex-col items-center justify-center text-center font-sans text-lg text-black scroll:text-pink bg-turq">
                <h class="text-xl text-bold text-indigo-900"> Investing in Persons Unknown
                <br/> <br/></h>
                <p>   
               We are looking for investors for our Loanstock scheme. Loanstock invested in our co-operative will part finance the purchase of property which will provide sustainable, affordable and self-managed housing in South Liverpool for generations to come. We are looking for pledges for Loanstock in advance of finding our perfect property/site so we are ready to spring into action when the right opportunity comes along.
               <br/><br/>
               </p>
                <p>   
               We are registered with the Financial Conduct Authority under the Co-operative and Community Benefit Societies Act 2014. We have prepared our business plan and financial forecast for the project and have had a £48,000 loan agreed in principle from Radical Routes. We already have £15,000 loanstock and are looking for more, we will apply for staged a new-build mortgage from the ecological building society. <br/><br/>
               </p>

  </div>


   
</div>


</template>

<script>
import {onMounted, ref, watch} from "vue";
import store from "@/store";


export default {
  setup() {
    let questionsData = ref(null)
    let questionData = ref(null)
    let currentQuestionId = ref(0)
    let currentQuestionType = ref("A")
    let isMoreQuestions = ref(true)

    function getSpecificQuestionData() {
      Object.values(questionsData.value).forEach(question => {
        if (question.id == currentQuestionId.value) {
          questionData.value = question.questionType.filter(questionDetail => questionDetail.option == currentQuestionType.value)
          console.log(questionData.value)
        }
      })
    }

    function getNewQuestionData(buttonName) {
      store.dispatch("saveResponse", buttonName)
      currentQuestionId.value++
      currentQuestionType.value = buttonName
      // pull another question only if there's more in store
      if (currentQuestionId.value < questionsData.value.length) {
        getSpecificQuestionData()
      } else {
        isMoreQuestions.value = false
      }
    }

    function restart() {
      window.location.reload()
    }

    onMounted(() => {
      //console.log(store.getters.enteredWeb)
      //store.dispatch("setEntered", "true")
      //console.log(store.getters.enteredWeb)
      console.log(store.getters.questions)
      questionsData.value = store.getters.questions
      getSpecificQuestionData()
    })

    watch(currentQuestionId, () => {
      console.log(currentQuestionId)
      console.log(store.getters.responses)
    })

    watch(currentQuestionType, () => {
      console.log(currentQuestionType)
    })

    return {
      questionsData,
      questionData,
      currentQuestionId,
      currentQuestionType,
      getNewQuestionData,
      isMoreQuestions,
      restart
    }
  }
}
</script>

<style scoped>

</style>